import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Card from 'react-bootstrap/Card';

export const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text.primary};
  :hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const ItemCard = styled(Card)`
  margin: 1rem;
  border-bottom: 3px solid ${({ theme }) => theme.colors.blue};
`;

export const TagsWrapper = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.small}
}
`;

export const Details = styled.p`
  color: #9aa0a6;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

export const Title = styled(Card.Title)`
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.xlarge};
`;

export const Excerpt = styled(Card.Text)`
  font-size: ${({ theme }) => theme.fontSize.medium};
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
