import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { IPost } from 'src/utils/ts/blog';
import Layout from 'src/components/common/Layout';
import { MetaData } from 'src/components/common/Meta';
import PostContent from 'src/components/PostContent';

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */

interface IPostPage {
  data: {
    ghostPost: IPost;
    allGhostPost: {
      edges: {
        node: IPost;
      }[];
    };
  };
  location: {
    pathname: string;
  };
  pageContext: {
    tags: string[];
  };
}

const Post = ({ data, location }: IPostPage): ReactElement => {
  const post = data.ghostPost;
  const relatedPosts = data.allGhostPost.edges;

  return (
    <>
      <MetaData data={data} location={location} type="article" />
      <Helmet>
        <style type="text/css">{`${post.codeinjection_styles}`}</style>
      </Helmet>
      <Layout>
        <PostContent post={post} relatedPosts={relatedPosts} />
      </Layout>
    </>
  );
};

export default Post;

export const postQuery = graphql`
  query($slug: String!, $tags: [String!]) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
    allGhostPost(
      sort: { fields: published_at, order: DESC }
      filter: { tags: { elemMatch: { slug: { in: $tags } } } }
      limit: 3
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;
