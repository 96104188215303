import React, { ReactElement } from 'react';
import { Link } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Tags } from '@tryghost/helpers-gatsby';
import { IPost } from 'src/utils/ts/blog';
import PostCard from 'src/components/BlogContent/PostCard';
import {
  Wrapper,
  TagsWrapper,
  FeatureImage,
  Header,
  Title,
  Details,
  Content,
  RelatedPosts,
  ViewMore,
} from './styles';

interface IPostContent {
  post: IPost;
  relatedPosts: { node: IPost }[];
}

const PostContent = ({
  post,
  relatedPosts,
}: IPostContent): ReactElement => {
  return (
    <Wrapper>
      <Row noGutters>
        <TagsWrapper>
          <Tags
            post={post}
            visibility="public"
            autolink={false}
            separator=" | "
          />
        </TagsWrapper>
      </Row>
      <Row noGutters>
        <Col>
          <article>
            {post.feature_image && (
              <FeatureImage>
                <img src={post.feature_image} alt={post.title} />
              </FeatureImage>
            )}
            <section>
              <Header>
                <Title>{post.title}</Title>
                <Details>
                  on {post.published_at_pretty} / by{' '}
                  {post.primary_author.name}
                </Details>
              </Header>

              {/* The main post content */}
              <Content
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </section>
          </article>
        </Col>
      </Row>
      <Row noGutters>
        <Col lg="12">
          <RelatedPosts>More similar blogs</RelatedPosts>
        </Col>
        <Col lg="12">
          <Row noGutters>
            {relatedPosts.map(({ node }: { node: IPost }) => (
              <PostCard key={node.id} post={node} />
            ))}
          </Row>
        </Col>
        <Col lg="12">
          <ViewMore>
            <Link to="/blog">View More Blogs</Link>
          </ViewMore>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default PostContent;
