import styled from '@emotion/styled';
import Container from 'react-bootstrap/Container';
import { Link } from 'gatsby';

export const Wrapper = styled(Container)`
  margin-bottom: 2rem;
`;

export const TagsWrapper = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: bold;
  font-size: ${({ theme }) =>
    theme.sections.headlines.large.fontSize};
  margin: 1rem 0;
`;

export const FeatureImage = styled.figure`
  img {
    width: 100%;
    height: auto;
    
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
`;
export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: bold;
  font-size: 28px;
`;
export const Details = styled.span`
  color: #9aa0a6;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const Content = styled.section`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 18px;
  img {
    max-width: 100%;
    padding: 1rem;
    height: auto;
  }
  margin-bottom: 8rem;
`;

export const RelatedPosts = styled.h2`
  margin: 1rem 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: bold;
  font-size: 28px;
`;

export const ViewMore = styled.p`
  a {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: ${({ theme }) => theme.fontSize.xlarge};
  }
  text-align: center;
  margin: 5rem;
`;
